import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import TagMenu from "../../components/tagMenu"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  return (
    <Layout title={title}>
      <header className="page-head">
        <h2 className="page-head-title">All Tags</h2>
        <TagMenu />
      </header>

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <ul>
            <li><Link to={`/tags/media-collection`}>Things I'm Reading & Watching</Link></li>
            <li><Link to={`/tags/field-notes`}>Field Notes</Link></li>
          </ul>

          <br/>

          <table>
            <thead>
              <tr>
                <th>Tag</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {group.map(tag => (
                <tr>
                  <td>
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}`}>
                      {tag.fieldValue}
                    </Link>
                  </td>
                  <td>{tag.totalCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </article>
    </Layout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
