import React from "react"
import { Link } from "gatsby"

const TagMenu = props => {
  return (
    <nav className="tag-menu">
      <ul role="menu">
        <li role="menuitem">
          <Link to={`/blog`}>Writing</Link>
        </li>
        <li role="menuitem">
          <Link to={`/blog/itp`}>ITP NYU</Link>
        </li>
        <li role="menuitem">
          <Link to={`/blog/tags`}>Tags</Link>
        </li>
      </ul>
    </nav>
  )
}

export default TagMenu
